
import { defineComponent, PropType, computed, reactive, ref, watch } from "vue";
import moment, { Moment } from 'moment';
import { message } from "ant-design-vue";
import useModel from "@/hooks/useModel";
import { DataProps } from "../ApprovalChain/ProgramChain/type";
import { addNewApprovalChain, newVersionForCopy } from "@/API/approvalChain";
import { useRouter } from "vue-router";
import * as API from '@/API/approvalChain'
type TypeProp = "new" | "copy";
export default defineComponent({
  name: "App",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String as PropType<TypeProp>,
    },
    chainType: {
      type: String,
      default: "program",
    },
    chainList: {
      type: Array as PropType<DataProps[]>,
    },
    payoutType: {
      type: String,
      default: "DEALER",
    },
  },
  setup(props) {
    const _visible = useModel(props, "visible");
    const title = computed(() => {
      return props.type === "new"
        ? "Create Approval Chain-Add new"
        : "Create Approval Chain-Copy from existing";
    });
    const disabledDate = (current: Moment) => {
      return current && current < moment().subtract(1,'days');
    };
    // const chainList = ref([])
    // API.getCopiedApprovalChainName(props.chainType === 'program' ? 0 ：1).then((res: any) => {
    //   for (chainId in res) {
    //     chainList.value.push({
    //       chainId,
    //       chainName: res[chainId]
    //     })
    //   }
    // })
    const formState = reactive({
      buName: "",
      makeName: "",
      approvalChainName: "",
      approvalChainId: "",
      effective: moment(),
      copyForm: "",
    });
    const rules = reactive({
      copyForm: [
        { required: true, message: "Please select Copy From", trigger: "change" },
      ],
      buName: [
        { required: true, message: "Please select BU", trigger: "change" },
      ],
      makeName: [
        {
          required: true,
          message: "Please select make name",
          trigger: "change",
        },
      ],
      approvalChainName: [
        {
          required: true,
          message: "Please input approval chain name",
          trigger: "blur",
        },
        { min: 1, max: 50, message: 'Length should not be more than 50', trigger: 'blur' },
        {
          pattern: /^([a-z]|[A-Z]|[\u4e00-\u9fa5]|[0-9]|\s|_|-){0,}$/g,
          message: 'Including character which not allowed.',
          trigger: 'blur'
        }
      ],
      effective: [
        {
          required: true,
          type: "date",
          message: "Please select effective from",
          trigger: "change",
        },
      ]
    });
    const handleCancel = () => {
      _visible.value = false;
    };
    const formRef = ref();
    const router = useRouter();
    const handleConfirm = () => {
      if (formState.approvalChainName.length > 50) {
        message.error('Exceed Limited Length!')
        return false
      }
      let type = "";
      let payoutType = "";
      if (props.chainType === "program") {
        type = "0";
        payoutType = "";
      } else {
        type = "1";
        payoutType = props.payoutType;
      }
      formRef.value
        .validate()
        .then(() => {
          if (props.type === "new") {
            return addNewApprovalChain({
              approvalChainName: formState.approvalChainName,
              bu: formState.buName,
              effectiveFrom: moment(formState.effective).format(
                "YYYY/MM/DD hh:mm:ss"
              ),
              type,
              payoutType,
              make: formState.makeName,
            })
          } else {
            return newVersionForCopy({
              approvalChainName: formState.approvalChainName,
              bu: formState.buName,
              copedBusinessId: formState.approvalChainId,
              effectiveFrom: moment(formState.effective).format(
                "YYYY/MM/DD hh:mm:ss"
              ),
              type,
              payoutType,
              make: formState.makeName,
            })
          }
        })
        .then((res: any) => {
          _visible.value = false;
          if (res.code !== "0") {
            // message.error(res.message);
          } else {
            router.push({
              path: "/approvalChainEdit",
              query: {
                businessId: res.data[0],
                chainName: formState.approvalChainName,
                bu: formState.buName,
                type: props.chainType,
                make: formState.makeName,
                mode: 'edit'
              }
            })
            message.success(props.type === "new" ? "Add success" : "Copy success")
          }
        })
        .catch((error: any) => {
          console.log("error", error);
        });
    };
    const resetForm = () => {
      formRef.value.resetFields();
    };
    // 关闭弹窗清空表单
    watch(_visible, (val) => {
      if (!val) {
        resetForm();
      }
    });

    // 切换copy
    const getById = (arr: DataProps[], id: string) => {
      return arr.filter((item) => item.chainId === id)[0];
    };
    const handleCopyChange = (chainId: string) => {
      if (props.chainList) {
        const chain = getById(props.chainList, chainId);
        formState.approvalChainId = chain.businessId
        // formState.buName = chain.bu as string;
        // formState.approvalChainName = chain.chainName as string;
        // formState.effective = chain.effectiveFromStr as any;
        // if (props.chainType === "payout") {
        //   formState.makeName = chain.make as string;
        // }
      }
    };
    const makeList = ref(["VAN", "DENZA", "CBU", "PBP"])
    const handleBuChange = (value: any) => {
      formState.makeName = ''
      if (!value) {
         makeList.value = ["VAN", "DENZA", "CBU", "PBP"]
      } else if (value.indexOf('VAN') !== -1) {
         formState.makeName = 'VAN'
         makeList.value = ['VAN']
      } else if (value.indexOf('DENZA') !== -1) {
         formState.makeName = 'DENZA'
         makeList.value = ['DENZA']
      } else {
         makeList.value = ["CBU", "PBP"]
      }
    }
    return {
      _visible,
      title,
      formState,
      rules,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      formRef,
      handleCancel,
      handleConfirm,
      handleCopyChange,
      disabledDate,
      handleBuChange,
      makeList
    };
  },
});
